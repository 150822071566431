@font-face {
	font-family: "ProximaNovaBold";
	src: local("ProximaNovaBold"),
		url("./fonts/proxima_nova_bold/proxima_nova_bold.ttf") format("truetype");
}

@font-face {
	font-family: "ProximaNovaRegular";
	src: local("ProximaNovaRegular"),
		url("./fonts/proxima_nova_regular/proxima_nova_regular.ttf") format("truetype");
}

@font-face {
	font-family: "ProximaNovaLignt";
	src: local("ProximaNovaLignt"),
		url("./fonts/proxima_nova_light/proxima_nova_light.ttf") format("truetype");
}

@font-face {
	font-family: "RupsterScript";
	src: local("RupsterScript"),
		url("./fonts/rupster_script_free/rupster_script_free.ttf") format("truetype");
}

@font-face {
	font-family: "ArialBold";
	src: local("ArialBold"),
		url("./fonts/arial_bold/ArialBold.ttf") format("truetype");
}

body {
	margin: 0;
	font-family: 'ProximaNovaRegular';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: relative;
	line-height: 1.4;
}

* {
	padding: 0;
	margin: 0;
}

ul {
	list-style: none;
}

img {
	max-width: 100%;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
	text-decoration: none;
	cursor: pointer;
}

a.slide-link {
	color: #76cddd;
}

a.slide-link:hover {
	color: #fe9d17;
}

h1 {
	font-size: 36px;
}

h2 {
	font-size: 30px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@keyframes dropTop {
	0% {
		transform: translateY(-100%) scale(0);
		opacity: 0;
	}

	100% {
		transform: translateY(0%) scale(1);
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}